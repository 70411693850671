import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-changelog-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_timeline = _resolveComponent("bp-timeline")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_changelog.jpg",
      icon: ['fad', 'list-timeline']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Changelog")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": "",
      "active-tab": _ctx.activeTab,
      tabs: _ctx.tabs,
      "tabs-position": "inside"
    }, {
      "tabs-content": _withCtx(({ activeTabId }) => [
        _createVNode(_component_bp_timeline, {
          timeline: _ctx.changelogs.get(activeTabId) || [],
          active: _ctx.version,
          "empty-text": _ctx.$gettext('No changelog available.')
        }, null, 8, ["timeline", "active", "empty-text"])
      ]),
      _: 1
    }, 8, ["active-tab", "tabs"])
  ]))
}