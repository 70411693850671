
import { $gettext } from 'vue-gettext';
import { availableLanguages, currentLanguage, defaultTranslated, getTranslatedKey } from '@/translation';
import { Component, Product, useVersionStore } from '@/stores/version';
import { defineComponent, ref, watch } from 'vue';
import { Group, Tab } from '@/components/card/BpCard';
import { removeHTML } from '@/utils/string';
import { Timeline } from '@/components/timeline/BpTimeline';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'bp-changelog-view',
  props: {
    version: String,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    const changelogs = ref<Map<string, Timeline>>(new Map());
    watch(() => [versionStore.isLoading(), currentLanguage.value], () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      changelogs.value.clear();
      for (const versionNumber of versionStore.getVersionNumbers()) {
        const parent = versionStore.getById(versionNumber.parent_id) as Product | Component;
        if (!parent) {
          continue;
        }
        const id = versionStore.getUUIDPathById(versionNumber._id).join('_');
        const entries = changelogs.value.get(id) || [];
        changelogs.value.set(id, [
          ...entries,
          {
            _id: versionNumber.version,
            date: versionNumber.date,
            link: `${window.location.origin}/#${router.currentRoute.value.path}?tab=${id}&version=${versionNumber.version}`,
            name: Object.keys(availableLanguages).reduce((acc, lang) => {
              const key = getTranslatedKey(lang);
              return { ...acc, [key]: `${versionStore.getName(parent)} ${versionStore.getName(versionNumber)}` };
            }, {}),
            text: versionNumber.changelog ? Object.fromEntries(Object.entries(versionNumber.changelog).map(([key, text]) => {
              const meta = versionNumber.meta ? `<table style="border-collapse: collapse;">${Object.entries(versionNumber.meta)
                .filter(([metaKey, metaValue]) => !!versionStore.getTranslatedMetaKey(metaKey) && !!metaValue)
                .map(([metaKey, metaValue]) => `<tr><td style="padding: 0 var(--padding) 0 0; margin: 0;">${versionStore.getTranslatedMetaKey(metaKey)}</td><td style="padding: 0; margin: 0;">${metaValue}</td></tr>`)
                .join('')}</table>` : '';
              return [key, removeHTML(meta) ? `<strong><u>${$gettext('Detailed information')}</u></strong><p>&nbsp;</p>${`${meta}<p>&nbsp;</p>${text || '<em>' + $gettext('No changelog available.') + '</em>'}`}` : text];
            })) : defaultTranslated(),
          }
        ])
      }
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// TABS
    ///-------------------------------------------------------------------

    const activeTab = ref('')

    const tabs = ref<Group<Tab>[]>([])
    watch(() => versionStore.isLoading(), () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }

      // Tabs
      tabs.value = Array.from(
        versionStore.getVersionNumbers().reduce((acc, versionNumber) => {
          const key = versionStore.getUUIDPathById(versionNumber._id).join('_');
          const path = versionStore.getPathById(versionNumber._id);
          const product = path
            .filter(version => version.doc_type === 'version_product')
            .map(version => versionStore.getName(version))
            .join(' ')
          const component = path
            .filter(version => version.doc_type === 'version_component')
            .map(version => versionStore.getName(version))
            .join(' ')
          const value = { product, component, order: path.filter(version => version.order !== undefined).map(version => version.order).join('') }
          acc.set(key, value);
          return acc;
        }, new Map<string, { product: string, component: string, order: string }>())
      )
        .sort(([, { order: orderA }], [, { order: orderB }]) => orderA < orderB ? -1 : 1)
        .map(([_id, { product, component, order }]) => ({ _id, name: component, icon: versionStore.getIcon(_id), _excludedQueryParams: ['version'], _group: product, order }));

      // Initially active tab
      const tab = tabs.value.find(tab => tab._id === router.currentRoute.value.query['tab']);
      activeTab.value = tab !== undefined ? tab._id : tabs.value[0]._id;
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeTab,
      changelogs,
      tabs,
    }
  }
})
